export default {
  title: "ضمان الاحتياجات الأساسية للجميع",
  processing: "تحليل البيانات",
  install: "تنزيل",
  installText: "لتجربة أفضل، قم بتنزيل التطبيق",
  header: {
    signIn: "تسجيل الدخول",
    aboutUs: "من نحن",
    contact: "تواصل",
    terms: "الشروط والأحكام",
    overview: "نظرة عامة",
    updates: "التحديثات",
    impact: "الأثر",
    share: "شارك",
    shareThisCause: "شارك هذه القضية",
    allPrograms: "جميع البرامج",
    faqs: "الأسئلة الشائعة",
  },
  home: {
    no_program:
      "لا توجد برامج تبرع متاحة في الوقت الحالي. يرجى التحقق مرة أخرى قريبًا.",
    our_success: "شاهد نجاحاتنا",
    get_in_touch: "ابقى على تواصل",
  },
  footer: {
    explore: "استكشف",
    goal: "هدفنا هو تأمين غذاء صحي ومغذي للتخفيف من المعاناة الناتجة عن الجوع.",
    about: "عن بنك الطعام المصري",
    faq: "الأسئلة الشائعة",
    programs: "جميع القضايا",
    challenges: "إنشاء تحدي",
    sadaqah_jariyah: "إنشاء صدقة جارية",
    company: "الشركة",
    stories: "قصص النجاح",
    contact: "تواصل معنا",
    legal: "قانوني",
    terms: "شروط الخدمة",
    privacy: "سياسة الخصوصية",
    cookies: "سياسة الكوكيز",
    copyright: "بنك الطعام المصري. جميع الحقوق محفوظة.",
},
  ProgramListing: {
    title: "القضايا العاجلة",
    allTypes: "جميع المستفيدين",
    allDonations: "جميع التبرعات",
    noPrograms: "لا توجد قضايا متاحة في الوقت الحالي",
    noProgramsText:
      "يبدو أنه لا توجد أسباب للتبرع مدرجة الآن.  ,هل ترغب في البقاء على اطلاع دائم؟  ,أدخل بريدك الإلكتروني ليتم إعلامك عند إضافة أسباب جديدة.",
  },
  Partners: {
    title: "شركاؤنا الموثوقون",
  },
  impact: {
    title: "الأثر المحقق",
    Beneficiaries: "الأسر التي تم مساعدتها",
    YearsOfService: "سنوات من الخدمة للمجتمع",
    Donations: "عدد المتبرعين",
    TotalMeals: "إجمالي الوجبات الموزعة",
  },
  homepage: {
    secondTitle:
      "نحن نكافح انعدام الأمن الغذائي والجوع من خلال إنشاء برامج تبرع مستدامة توفر التغذية والأمل للمحتاجين",
    herroButtonText: "انظر قضايانا",
  },
  Categories: {
    title: "القضايا حسب المستفيدين",
    Kids: "الأطفال",
    "Build Tomorrow": "بناء الغد",
    Orphans: "الأيتام",
    Convoys: "القوافل",
  },
  ProgramCard: {
    raised_label: "التبرعات التي تم جمعها",
    goal_label: "الهدف",
    donate_now: "تبرع الآن",
    preview_label: "معاينة",
  },
  update: {
    meal_provision: "وجبات مقدمة",
    box_provision: "صناديق مؤن موزعة",
    share: "شارك",
    no_updates:
      "لا توجد تحديثات متاحة في الوقت الحالي. يرجى التحقق مرة أخرى قريبًا.",
    today: "اليوم",
    yesterday: "أمس",
    more: "المزيد",
  },
  information: {
    address: "العنوان",
    phone: "الهاتف",
    email: "البريد الإلكتروني",
    contactUs: "تواصل معنا",
    aboutUs: "من نحن",
    who: "من هو بنك الطعام المصري؟",
    faq: "الأسئلة الشائعة",
    getInTouch: "تواصل معنا",
    chat: "تواصل معنا",
    call: "اتصل بنا",
  },
  getInTouchPage: {
    title: "هيا بنا نتواصل",
  },
  aboutPage: {
    title: "من هم دائرة الإنسانية؟",
    in: "في",
    establishment: "تم إنشاء بنك الطعام المصري (EFB)",
    volunteers: "الشركاء المتطوعون",
    meals: "الوجبات المقدمة من",
    to: "إلى",
    transparency: "الشفافية",
    transparency_description:
      "نحن نضمن لك تتبع تبرعاتك وفهم كيفية إنفاقها ورؤية تأثيرها.",
    collaboration: "التعاون",
    collaboration_description:
      "نحن نؤمن بقوة الشراكات، والعمل معاً بفعالية مع المتبرعين والمتطوعين والمنظمات للقضاء على الجوع.​",
    sustainability: "الاستدامة",
    sustainability_description:
      "نركز على إيجاد حلول دائمة تعزز الأمن الغذائي والاعتماد على الذات.​",
    spirit: "روح الفريق",
    spirit_description:
      "روحنا التعاونية تغذي دافعنا الجماعي لإحداث تأثير ملموس.",
    passion: "الشغف",
    passion_description:
      "نقترب من عملنا بشغف لا يتزعزع، مدفوعين بالتزامنا العميق بمهمتنا.",
    excellence: "التميز",
    excellence_description:
      "التزامنا بالتميز يتضمن الحفاظ على أعلى المعايير، وضمان المساءلة، وتحسين أنظمتنا وعملياتنا باستمرار​",
    values: "قيمنا",
    mentioned: "لقد تم ذكرنا في",
    awards: "الجوائز والتقدير",
    believe:
      "نحن نؤمن بقوة التعاطف الجماعي ومسؤوليتنا المشتركة لرفع مستوى المحتاجين.",
    everyone:
      "هل تؤمن أن الجميع يستحقون الوصول إلى غذاء مغذي وحياة خالية من الجوع؟",
    already: "إذا كان الأمر كذلك، فأنت بالفعل جزء من دائرة الإنسانية.",
    join: "انضم إلى الدائرة",
    annual: "أبرز تأثيرنا السنوي",
    approach: "شاهد نهجنا",
    ourapproach: "نهجنا",
  },
  faqsPage: {
    title: "لديك أسئلة؟ <br/> لدينا إجابات",
    question: "ما زلت تملك أسئلة؟",
    contact_support: "اتصل بفريق الدعم لدينا لمزيد من المساعدة",
  },
  program: {
    impact_number: "الإحصائيات",
    faq_title: "الأسئلة الشائعة",
  },
  updates: {
    title: "المستجدات",
    share: "شارك",
  },
  paymentState: {
    thankYou: "شكراً لإحداث فرق!",
    donationProcessed:
      "تمت معالجة تبرعك بقيمة <bold>{{amount}}</bold> بنجاح. تم إرسال رسالة تأكيد عبر البريد الإلكتروني إلى <bold>{{email}}</bold>.",
    cashDonationProcessed:
      "تم تسجيل تبرعك بقيمة <bold>{{amount}}</bold> بنجاح. سيتصل بك فريقنا لترتيب استلام تبرعك. تم إرسال رسالة تأكيد عبر البريد الإلكتروني إلى <bold>{{email}}</bold>.",
    createAccountPrompt: "هل ترغب في إنشاء حساب ل:",
    createAccountButton: "نعم، أنشئ حسابًا",
    backToHome: "العودة إلى الصفحة الرئيسية",
    shareWithFriends: "شارك مع الأصدقاء",
    impact: {
      Donations: "التبرعات",
      Beneficiaries: "المستفيدين",
      "Food boxes": "صناديق الطعام",
      "Meals provided": "الوجبات المقدمة",
    },
    MoreCauses: "المزيد من القضايا لإحداث فرق",
    paymentFailed: "فشل الدفع",
    paymentFailedMessage:
      "حاولنا خصم المبلغ من بطاقتك ولكن حدث خطأ ما. يرجى إعادة المحاولة مرة أخرى. إذا واجهت أي صعوبات أخرى، يرجى الاتصال بنا للحصول على المساعدة.",
    retryPaymentButton: "أعد محاولة الدفع",
    contactSupport: "هل لديك سؤال؟ اتصل بالدعم",
    here: "هنا",
    creditCard: "بطاقة الائتمان",
    payInPerson: "الدفع شخصياً",
    createAccount1: "تتبع جميع تبرعاتك وشاهد الفرق الذي تحققه.",
    createAccount2: "قم بتعديل أو إيقاف تبرعاتك الشهرية.",
    createAccount3: "ابقى على اطلاع دائم على القضايا الجديدة.",
    createAccount4:
      "يمكنك تحميل إيصالات التبرعات الخاصة بك مباشرة من حسابك الشخصي في أي وقت.",
   bankInstallment: "التقسيط البنكي",
    buyNowPayLater: "اشتري الآن، وادفع لاحقاً",
    reason: "سبب",
  },
  ourPrograms: {
    title: "قضايانا",
    filter: "نوع المستفيد",
    allPrograms: "جميع القضايا",
    allCauses: "جميع القضايا",
    kids: "الأطفال",
    health: "الصحة",
    sort: "ترتيب حسب",
    close: "قريب من الهدف",
    launched: "تم إطلاقه حديثاً",
    orphans: "الأيتام",
    nurseryChildren: "أطفال الحضانة",
    singleMothers: "الأمهات المُعيلة",
    refugees: "اللاجئون",
    disabled: "ذوي الاحتياجات الخاصة",
    assisted: "المساعدة",
    donationType: "نوع التبرع",
    allTypes: "جميع الأنواع",
    zakat: "الزكاة",
    sadaqah: "الصدقة",
    jariya: "الصدقة الجارية",
    aqeeqah: "العقيقة",
    filterBy: "تصفية حسب",
    clearAll: "مسح الكل",
    apply: "تطبيق",
    notifyMe: "أخطرني",
  },
  policies: {
    privacy: {
      title: "سياسة الخصوصية",
      subtitle: "تاريخ النفاذ: 22 يوليو 2024",
      description: `
    <p>مرحبًا بكم في دائرة الإنسانية، التي يديرها بنك الطعام المصري (EFB). تشرح سياسة الخصوصية هذه كيفية جمع معلوماتك واستخدامها والكشف عنها وحمايتها عند زيارة موقعنا الإلكتروني coh.efb.eg (الموقع). يرجى قراءة هذه السياسة بعناية. إذا كنت لا توافق على شروط سياسة الخصوصية هذه، يرجى عدم الوصول إلى الموقع.</p>
    <br></br>
    <p id="collect"><strong>1. المعلومات التي نجمعها</strong></p>
    <p>a. المعلومات الشخصية: قد نجمع معلومات شخصية مثل اسمك وعنوان بريدك الإلكتروني ورقم هاتفك وعنوانك عندما تقدمها لنا طواعية من خلال النماذج على الموقع، مثل نماذج التبرعات أو نماذج الاتصال.</p>
    <p>b. المعلومات غير الشخصية: قد نجمع معلومات غير شخصية تلقائيًا أثناء تنقلك عبر الموقع. قد يشمل ذلك تفاصيل الاستخدام وعناوين IP والمعلومات التي تم جمعها من خلال ملفات تعريف الارتباط وتقنيات التتبع الأخرى.</p>
    <br></br>

    <p id="use"><strong>2. استخدام المعلومات</strong></p>
    <p>a. قد نستخدم المعلومات التي نجمعها، بما في ذلك المعلومات الشخصية، لـ:<p/>
    <ul>
      <li>تقديم وإدارة الخدمات المقدمة من خلال الموقع.</li>
      <li>التواصل معك، بما في ذلك الرد على استفساراتك وتقديم التحديثات.</li>
      <li>معالجة التبرعات والحفاظ على سجلات المتبرعين.</li>
      <li>تحسين محتوى ووظائف الموقع.</li>
      <li>الامتثال للالتزامات القانونية.</li>
    </ul>
    <p>b. لن نبيع أو نتاجر أو ننقل معلوماتك الشخصية إلى أطراف ثالثة دون موافقتك، باستثناء ما هو موضح في سياسة الخصوصية هذه أو كما يقتضيه القانون.</p>
    <br></br>

    <p id="disclosure"><strong>3. الكشف عن المعلومات</strong></p>
    <p>a. قد نكشف معلوماتك، بما في ذلك المعلومات الشخصية، لمقدمي الخدمات الخارجيين الذين يساعدوننا في تشغيل الموقع وتقديم خدماتنا. هؤلاء مقدمو الخدمات ملزمون باستخدام معلوماتك فقط بالقدر اللازم لأداء خدماتهم لنا.</p>
    <p>b. قد نكشف أيضًا معلوماتك للامتثال للالتزامات القانونية، أو فرض سياساتنا، أو حماية حقوقنا أو ممتلكاتنا أو سلامتنا أو سلامة الآخرين.</p>
    <br></br>

    <p id="data"><strong>4. أمان البيانات</strong></p>
    <p>نحن ملتزمون بضمان أمان معلوماتك. لقد قمنا بتنفيذ تدابير أمان عالمية لحماية معلوماتك الشخصية من الوصول غير المصرح به أو الاستخدام أو التغيير أو الكشف. تشمل هذه التدابير:</p>
    <ul>
      <li>تشفير البيانات المنقولة بين الموقع والمستخدمين.</li>
      <li>أنظمة تخزين آمنة وضوابط وصول مقيدة لحماية المعلومات الشخصية المخزنة.</li>
      <li>مراجعة وتحسين منتظم لممارسات أمان المعلومات لدينا لتخفيف المخاطر.</li>
    </ul>
    <p>بينما نسعى لحماية معلوماتك الشخصية، يرجى العلم بأنه لا توجد طريقة نقل عبر الإنترنت أو طريقة تخزين إلكترونية آمنة تمامًا. لذلك، لا يمكننا ضمان الأمان المطلق.</p>
    <br></br>

    <p id="choices"><strong>5. اختياراتك</strong></p>
    <p>يمكنك اختيار عدم تزويدنا بمعلومات معينة، ولكن هذا قد يحد من قدرتك على استخدام ميزات معينة في الموقع.</p>
    <br></br>

    <p id="privacy"><strong>6. خصوصية الأطفال</strong></p>
    <p>الموقع غير موجه للأطفال دون سن 13 عامًا. نحن لا نجمع معلومات شخصية من الأطفال دون سن 13 عامًا عن عمد. إذا علمنا أننا جمعنا أو تلقينا معلومات شخصية من طفل دون سن 13 دون التحقق من موافقة الوالدين، سنقوم بحذف تلك المعلومات.</p>
    <br></br>

    <p id="changesP"><strong>7. تغييرات على سياسة الخصوصية هذه</strong></p>
    <p>قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنخطرك بأي تغييرات عن طريق نشر السياسة الجديدة على الموقع بتاريخ سريان محدث. إن استمرارك في استخدام الموقع بعد تاريخ السريان يعتبر قبولًا لسياسة الخصوصية المحدثة.</p>
    <br></br>

    <p id="contactP"><strong>8. اتصل بنا</strong></p>
    <p>إذا كانت لديك أي أسئلة حول سياسة الخصوصية هذه أو ممارسات الخصوصية الخاصة بنا، يرجى الاتصال بنا <b>هنا</b>.</p>
  `,
      sidebarInfo: `
    <ul>
      <li><a href="#collect">المعلومات التي نجمعها</a></li>
      <li><a href="#use">استخدام المعلومات</a></li>
      <li><a href="#disclosure">الكشف عن المعلومات</a></li>
      <li><a href="#data">أمان البيانات</a></li>
      <li><a href="#choices">اختياراتك</a></li>
      <li><a href="#privacy">خصوصية الأطفال</a></li>
      <li><a href="#changesP">تغييرات على سياسة الخصوصية هذه</a></li>
      <li><a href="#contactP">اتصل بنا</a></li>
    </ul>
  `,
    },
    cookies: {
      title: "سياسة ملفات تعريف الارتباط",
      subtitle: "تاريخ النفاذ: 22 يوليو 2024",
      description: `
      <p>مرحبًا بكم في دائرة الإنسانية، الموقع الرسمي لبنك الطعام المصري. نحن ملتزمون بحماية خصوصيتك ونسعى لضمان الشفافية بشأن كيفية استخدامنا لملفات تعريف الارتباط على موقعنا. باستخدام هذا الموقع، فإنك توافق على استخدامنا لملفات تعريف الارتباط كما هو موضح في هذه السياسة.</p>
      <br></br>
      <p id="what"><strong>1. ما هي ملفات تعريف الارتباط؟</strong></p>
      <p>ملفات تعريف الارتباط هي ملفات نصية صغيرة يتم تخزينها على جهاز الكمبيوتر أو الجهاز المحمول الخاص بك عند زيارة موقع ويب. تساعد هذه الملفات في تحسين تجربتك في التصفح، وتقديم خدمات مخصصة، وتحليل أنماط الاستخدام.</p>
      <br></br>
      <p id="how"><strong>2. كيفية استخدامنا لملفات تعريف الارتباط</strong></p>
      <p>نستخدم ملفات تعريف الارتباط لتعزيز وظائف موقع دائرة الإنسانية وتجربة المستخدم الخاصة بك. قد تشمل استخداماتنا لملفات تعريف الارتباط:</p>
      <ul>
        <li>تذكر تفضيلاتك وإعداداتك: مثل لغة الموقع.</li>
        <li>تحليل استخدام الموقع: لفهم كيفية استخدام الزوار لموقعنا وتحسينه بناءً على هذه البيانات.</li>
        <li>تحسين الأداء: لضمان تشغيل الموقع بكفاءة وسلاسة.</li>
        <li>الإعلانات المستهدفة: لعرض الإعلانات الأكثر صلة بك.</li>
      </ul>
      <br></br>
      <p id="types"><strong>3. أنواع ملفات تعريف الارتباط التي نستخدمها</strong></p>
      <ul>
        <li>ملفات تعريف الارتباط الأساسية: هذه الملفات ضرورية لتمكينك من التنقل في الموقع واستخدام ميزاته الأساسية.</li>
        <li>ملفات تعريف الارتباط الوظيفية: تستخدم لتذكر تفضيلاتك وتعزيز تجربتك على الموقع.</li>
        <li>ملفات تعريف الارتباط التحليلية: تساعدنا في جمع معلومات حول كيفية استخدام الزوار لموقعنا، مما يساعدنا في تحسين محتواه وأدائه.</li>
        <li>ملفات تعريف الارتباط الإعلانية: تستخدم لتقديم إعلانات مخصصة بناءً على اهتماماتك.</li>
      </ul>
      <br></br>
      <p id="managing"><strong>4. إدارة ملفات تعريف الارتباط</strong></p>
      <p>يمكنك التحكم في وإدارة ملفات تعريف الارتباط من خلال إعدادات المتصفح الخاص بك. يمكنك اختيار رفض أو حذف بعض أو كل ملفات تعريف الارتباط. يرجى ملاحظة أن تعطيل ملفات تعريف الارتباط قد يؤثر على تجربتك على موقعنا وقد لا تتمكن من الوصول إلى بعض الميزات.</p>
      <br></br>
      <p id="links"><strong>5. الروابط إلى المواقع الخارجية</strong></p>
      <p>قد يحتوي موقعنا على روابط لمواقع ويب أخرى تديرها جهات خارجية. نحن غير مسؤولين عن محتوى أو ممارسات الخصوصية لهذه المواقع الأخرى. نوصي بمراجعة سياسات ملفات تعريف الارتباط والخصوصية لأي موقع تزوره.</p>
      <br></br>
      <p id="changesC"><strong>6. تغييرات في سياسة ملفات تعريف الارتباط</strong></p>
      <p>قد نقوم بتحديث سياسة ملفات تعريف الارتباط هذه من وقت لآخر لتعكس التغييرات في ممارساتنا أو القوانين المعمول بها. سننشر أي تغييرات على هذه الصفحة. نوصي بمراجعة هذه الصفحة بانتظام للبقاء على اطلاع بكيفية استخدامنا لملفات تعريف الارتباط.</p>
      <br></br>
      <p id="contactC"><strong>7. اتصل بنا</strong></p>
      <p>إذا كانت لديك أي أسئلة أو مخاوف بشأن سياسة ملفات تعريف الارتباط الخاصة بنا، يرجى الاتصال بنا <b>هنا</b>.</p>
    `,
      sidebarInfo: `
      <ul>
        <li><a href="#what">ما هي ملفات تعريف الارتباط؟</a></li>
        <li><a href="#how">كيفية استخدامنا لملفات تعريف الارتباط</a></li>
        <li><a href="#types">أنواع ملفات تعريف الارتباط التي نستخدمها</a></li>
        <li><a href="#managing">إدارة ملفات تعريف الارتباط</a></li>
        <li><a href="#links">الروابط إلى المواقع الخارجية</a></li>
        <li><a href="#changesC">تغييرات في سياسة ملفات تعريف الارتباط</a></li>
        <li><a href="#contactC">اتصل بنا</a></li>
      </ul>
    `,
    },
    terms: {
      title: "شروط الاستخدام",
      subtitle: "تاريخ النفاذ: 22 يوليو 2024",
      description: `
      <p>مرحبًا بكم في دائرة الإنسانية، الموقع الرسمي لبنك الطعام المصري. من خلال الوصول إلى هذا الموقع واستخدامه، فإنك توافق على الامتثال للشروط والأحكام التالية والالتزام بها. يرجى قراءتها بعناية.</p>
      <br></br>
      <p id="acceptance"><strong>1. قبول الشروط</strong></p>
      <p>من خلال الوصول إلى واستخدام موقع دائرة الإنسانية، فإنك تقبل وتوافق على الالتزام بالشروط والأحكام الخاصة بهذه الاتفاقية. بالإضافة إلى ذلك، عند استخدام هذه الخدمات الخاصة، ستكون خاضعًا لأي إرشادات أو قواعد منشورة تنطبق على هذه الخدمات. أي مشاركة في هذه الخدمة ستشكل قبولًا لهذه الاتفاقية. إذا كنت لا توافق على الامتثال لما سبق، يرجى عدم استخدام هذه الخدمة.</p>
      <br></br>
      <p id="changesT"><strong>2. تغييرات في الشروط</strong></p>
      <p>تحتفظ دائرة الإنسانية بالحق في تعديل هذه الشروط في أي وقت. ستكون أي تغييرات سارية المفعول فور نشرها على الموقع. استمرار استخدامك للموقع بعد نشر التغييرات يعني قبولك لهذه التغييرات.</p>
      <br></br>
      <p id="useT"><strong>3. استخدام الموقع</strong></p>
      <ul>
        <li>الأهلية: هذا الموقع مخصص للاستخدام من قبل الأفراد الذين يبلغون من العمر 18 عامًا أو أكثر. باستخدام هذا الموقع، فإنك تقر وتضمن أنك لا تقل عن 18 عامًا.</li>
        <li>سلوك المستخدم: توافق على استخدام الموقع لأغراض قانونية فقط وبطريقة لا تنتهك حقوق الآخرين أو تقيد أو تمنع استخدامهم واستمتاعهم بالموقع. يشمل السلوك المحظور:

          <li>مضايقة أو إزعاج أو تسبب في إزعاج أو قلق لأي مستخدم آخر.</li>
          <li>إرسال محتوى فاحش أو مسيء.</li>
          <li>تعطيل التدفق الطبيعي للحوار داخل الموقع.</li>

        </li>
      </ul>
      <br></br>
      <p id="intellectual"><strong>4. حقوق الملكية الفكرية</strong></p>
      <ul>
        <li>المحتوى: جميع المحتويات المدرجة في هذا الموقع، مثل النصوص والرسومات والشعارات والصور ومقاطع الصوت والبرمجيات، هي ملك لبنك الطعام المصري أو موردي محتواه وهي محمية بقوانين حقوق النشر المصرية والدولية.</li>
        <li>العلامات التجارية: العلامات التجارية والشعارات وعلامات الخدمة المعروضة على الموقع هي ملك لبنك الطعام المصري. يُحظر على المستخدمين استخدام أي من هذه العلامات دون إذن كتابي مسبق من بنك الطعام المصري.</li>
      </ul>
      <br></br>
      <p id="donations"><strong>5. التبرعات والمدفوعات</strong></p>
      <ul>
        <li>معالجة الدفع: تتم معالجة التبرعات المقدمة من خلال موقع دائرة الإنسانية عبر معالجات دفع آمنة تابعة لجهات خارجية. من خلال تقديم تبرع، توافق على الامتثال للشروط والأحكام الخاصة بهذه المعالجات الدفع التابعة لجهات خارجية.</li>
        <li>يتم التعامل مع المدفوعات المتكررة بأمان من خلال معالجات الدفع التابعة لجهات خارجية ولا تقوم دائرة الإنسانية بتخزين بيانات الدفع الخاصة بك بأي شكل من الأشكال.</li>
        <li>سياسة الاسترداد: إذا كنت تعتقد أنه حدث خطأ في تبرعك، يرجى الاتصال بنا على الفور <b>هنا</b>.</li>
      </ul>
      <br></br>
      <p id="privacyT"><strong>6. سياسة الخصوصية</strong></p>
      <p>يخضع استخدامك للموقع أيضًا لسياسة الخصوصية الخاصة بنا. يرجى مراجعة سياسة الخصوصية الخاصة بنا للحصول على معلومات حول كيفية جمعنا واستخدامنا وحماية معلوماتك الشخصية.</p>
      <br></br>
      <p id="disclaimer"><strong>7. إخلاء المسؤولية عن الضمانات</strong></p>
      <p>يتم تقديم هذا الموقع ومكوناته لأغراض إعلامية فقط؛ دائرة الإنسانية ليست مسؤولة أو ملزمة بدقة أو فائدة أو توفر أي معلومات يتم نقلها أو إتاحتها عبر الموقع، وليست مسؤولة عن أي خطأ أو إغفال في تلك المعلومات.</p>
      <br></br>
      <p id="liability"><strong>8. تحديد المسؤولية</strong></p>
      <p>في أي حال من الأحوال لن تكون دائرة الإنسانية أو الشركات التابعة لها أو أي من مديريها أو مسؤوليها أو موظفيها أو وكلائها أو مقدمي المحتوى أو الخدمة مسؤولين عن أي أضرار غير مباشرة أو خاصة أو عرضية أو تبعية أو عقابية تنشأ عن أو مرتبطة مباشرة أو غير مباشرة باستخدام الموقع أو المحتوى أو المواد والوظائف المتعلقة بها.</p>
      <br></br>
      <p id="governing"><strong>9. القانون الحاكم</strong></p>
      <p>تخضع هذه الشروط وتفسر وفقًا لقوانين جمهورية مصر العربية، دون النظر إلى مبادئ تعارض القوانين. يجب إقامة أي إجراء قانوني أو إجراءات تتعلق بوصولك إلى الموقع أو استخدامه في محكمة مختصة في مصر.</p>
      <br></br>
      <p id="contactT"><strong>10. معلومات الاتصال</strong></p>
      <p>إذا كان لديك أي أسئلة حول هذه الشروط والأحكام، يرجى الاتصال بنا <b>هنا</b>.</p>
    `,
      sidebarInfo: `
      <ul>
        <li><a href="#acceptance">قبول الشروط</a></li>
        <li><a href="#changesT">تغييرات في الشروط</a></li>
        <li><a href="#useT">استخدام الموقع</a></li>
        <li><a href="#intellectual">حقوق الملكية الفكرية</a></li>
        <li><a href="#donations">التبرعات والمدفوعات</a></li>
        <li><a href="#privacyT">سياسة الخصوصية</a></li>
        <li><a href="#disclaimer">إخلاء المسؤولية عن الضمانات</a></li>
        <li><a href="#liability">تحديد المسؤولية</a></li>
        <li><a href="#governing">القانون الحاكم</a></li>
        <li><a href="#contactT">معلومات الاتصال</a></li>
      </ul>
    `,
    },
    cookieBannerTitle: " نحن نقدر خصوصيتك",
    cookiesBannerText:
      '</underline>نحن نستخدم ملفات تعريف الارتباط لتحسين تجربة التصفح الخاصة بك وتقديم محتوى مخصص وتحليل حركة المرور لدينا. بالنقر على "قبول الكل"، فإنك توافق على استخدامنا لملفات تعريف الارتباط. راجع <underline>سياسة ملفات تعريف الارتباط',
    cookieAccept: "قبول الكل ",
    cookieReject: " إعادة الإرسال",
  },
  notFound: {
    title: "٤٠٤",
    message: "الصفحة غير موجودة!",
    description:
      "نعتذر، الصفحة التي تبحث عنها قد تمت إزالتها أو إعادة تسميتها أو غير متاحة. يرجى العودة إلى الصفحة الرئيسية.",
    backHome: "الذهاب إلى الصفحة الرئيسية",
  },
  serverError: {
    title: "٥٠٠",
    message: "عذرًا، حدث خطأ ما!",
    description:
      "نعتذر، ولكن يبدو أن هناك خطأ من طرفنا. يرجى تحديث الصفحة أو العودة لاحقًا.",
  },
  maintenance: {
    title: "صيانة",
    message: "نحن نقوم بتحسينات!",
    description:
      "موقعنا الإلكتروني يخضع حاليًا لصيانة مجدولة لتحسين خدمتنا لك. سنعود عبر الإنترنت قريبًا. يرجى إعادة المحاولة بعد قليل.",
  },
  myDonations: {
    title: "تبرعاتي",
    active_subscriptions: "الاشتراكات النشطة",
    subscriptions: "الاشتراكات",
    allMyDonations: "كل التبرعات",
    donation_info: "معلومات التبرع",
    cause: "القضية",
    type: "النوع",
    amount: "المبلغ",
    date: "التاريخ",
    receipt: "الإيصال",
    monthlyAmount: "المبلغ الشهري",
    endDate: "تاريخ الانتهاء",
    modify: "تعديل",
    editSubscription: "تعديل الاشتراك",
    checkReceipt: "تحقق من الإيصال",
    subscription: "الاشتراك",
    oneTime: "التبرع مرة واحدة،",
    seeAllCauses: "عرض جميع القضايا",
    donationHistory: "سجل التبرعات",
    pauseDonation: "إيقاف التبرع",
    yesCancelDonation: "نعم، ألغِ التبرع",
    cancellationText:
      "هل أنت متأكد من أنك تريد إلغاء تبرعك الشهري لسبب {{cause}}؟",
    cancelledSuccessTitle: "تم إلغاء التبرع",
    cancelledSuccess: "تم إلغاء تبرعك الشهري بنجاح. شكراً لمساهماتك السابقة.",
    saveChanges: "احفظ التغييرات",
    cancelMySubscription: "إلغاء اشتراكي",
    resumeMySubscription: "مواصلة اشتراكي",
    chooseFinalMonth: "اختر الشهر الأخير للتبرع",
    orWriteAmount: "أو اكتب المبلغ",
    editMonthlyDonation: "تعديل التبرع الشهري",
    donationBreakdown: "تفاصيل التبرعات",
    yourYear: "عامك {{year}}",
    active: "نشيط",
    paused: "متوقف",
    cancelled: "ألغيت",
    status: "الحالة",
    moreCauses: "عرض القضايا",
    similarCauses: "قضايا مشابهة",
    causeFromGround: "تحديثاتك",
    impact: "أثرك",
    loadMore: "عرض المزيد",
  },
  donorProfile: {
    myUpdates: "التأثير الشخصي",
    myDonations: "التبرعات",
    accountDetails: "الحساب",
    logout: "تسجيل الخروج",
    challenges: "التحديات",
  },
  myUpdates: {
    header: "التحديثات من الميدان",
    familiesHelped: "الأسر التي تمت مساعدتها",
    totalMeals: "إجمالي الوجبات الموزعة",
    causesSupported: "القضايا المدعومة",
    makeFirstDonation: "قدم تبرعك الأول للبدء",
    emptyStateMessage:
      "لم تتلق أي تحديثات بعد. بمجرد تقديمك لتبرع، ستبدأ في رؤية قصص وتحديثات مخصصة مباشرة من الميدان. استكشف القضايا أدناه التي تهمك وقدم تبرعك الأول اليوم!",
  },
  profile: {
    firstName: "الاسم الشخصي",
    firstNamePlaceholder: "أدخل الاسم الشخصي",
    lastName: "الاسم العائلي",
    lastNamePlaceholder: "أدخل الاسم العائلي",
    email: "البريد الإلكتروني",
    emailPlaceholder: "أدخل بريدك الإلكتروني",
    phoneNumber: "رقم الهاتف",
    phoneNumberPlaceholder: "أدخل رقم هاتفك",
    country: "البلد",
    countryPlaceholder: "اختر بلدك",
    city: "المدينة",
    cityPlaceholder: "اختر مدينتك",
    preferredLanguage: "اللغة المفضلة",
    preferredLanguagePlaceholder: "اختر لغتك المفضلة",
    preferredCurrency: "العملة المفضلة",
    preferredCurrencyPlaceholder: "اختر عملتك المفضلة",
    personalDetails: "التفاصيل الشخصية",
    languageAndCurrency: "اللغة والعملة",
    manageAccount: "إدارة الحساب",
    paymentMethods: "طرق الدفع",
    deleteAccount: "حذف الحساب",
    delete: "حذف",
    logout: "تسجيل الخروج",
    editPhoto: "تعديل الصورة",
    saveChanges: "حفظ التغييرات",
    cancel: "إلغاء",
    confirm: "تأكيد",
    dismiss: "إلغاء",
    doYouMind: "هل تمانع أن تخبرنا لماذا؟",
    send: "إرسال",
    confirmLogout: "تأكيد الخروج",
    areYouSureLogout: "هل أنت متأكد من أنك تريد تسجيل الخروج؟",
    deleteYourAccount: "حذف حسابك",
    deleteSuccess: "تم حذف حسابك بنجاح",
    deleteCause: "لماذا تريد حذف حسابك؟",
    hopeToSeeYou: "نأمل أن نراك مرة أخرى قريبًا!",
    returnHomepage: "العودة إلى الصفحة الرئيسية",
    remove: "إزالة",
    save: "حفظ",
    finish: "إنهاء",
    english: "الإنجليزية",
    arabic: "العربية",
    egp: "الجنيه المصري",
    usd: "الدولار الأمريكي",
    deleteText:
      "هل انت متأكد انك تريد حذف حسابك؟ بمجرد حذف حسابك، لا يمكن التراجع عن ذلك<br />سيتم حذف جميع بياناتك نهائيًا، بما في ذلك معلومات ملفك الشخصي وتفضيلاتك وأي سجل نشاط<br />يرجى تخصيص بعض الوقت للنظر في الآثار المترتبة على ذلك قبل المتابعة",
    reason1: "لقد وجدت بنك طعام آخر يقدم خدمات أفضل",
    reason2: "الموقع لا يوفر ما يكفي من جمع التبرعات",
    reason3: "بعض الخدمات التي احتجتها لم تكن متوفرة",
    reason4: "المعلومات المقدمة لم تكن مرضية",
    reason5: "كان من الصعب استخدام الموقع والتنقل فيه",
    otherReasons: "تحديد أسباب أخرى",
    byDeleting: "بحذف حسابك، فإنك تقبل بـ",
    terms: "الشروط والأحكام الخاصة بنا",
    changes: "تم تطبيق التغييرات الخاصة بك",
    addCard: "إضافة بطاقة",
  },
  donateNow: {
    generalDonation: "تبرع عام",
    zakat: "الزكاة",
    sadaqah: "الصدقة",
    jariya: "الصدقة الجارية",
    aqeeqah: "العقيقة",
    donationType: "اختر نوع التبرع",
    donationAmout: "اختر مبلغ التبرع",
    donationCause: "اختر سبب التبرع",
    donationFrequency: "اختر تكرار التبرع",
    monthly: "التبرع شهرياً",
    once: "التبرع مرة واحدة",
    monthlyAmount: "المبلغ الشهري",
    endDate: "تاريخ الانتهاء",
    modify: "تعديل",
    enterDonationAmount: "أدخل مبلغ التبرع",
  },
  testimonials: {
    first_title: "ماذا يقول شركائنا",
    second_title: "ماذا يقول المستفيدون من مساعداتنا",
  },
  highlightedLink: {
    all_causes: "جميع القضايا",
    see_more: "تعلم المزيد",
    all_causes_mobile: "جميع",
    see_more_mobile: "أنظر إلى تأثيرنا",
  },
  sidebar: {
    all_programs: "البرامج",
    challenge: "اصنع تحديًا",
    about: "حول دائرة الإنسانية",
    faqs: "الأسئلة الشائعة",
    contact: "اتصل بنا",
    sadaqah: "أنشئ صدقة جارية لأحبائك",
  },
  poweredBy: {
    first_part: "برعاية",
    second_part: "بنك الطعام المصري",
  },
  join: {
    first_part: "انضم إلي",
    second_part: "دائرة الإنسانية",
  },
  quickDonation: {
    title: "التبرع السريع",
    proceedPayment: "المتابعة للدفع",
    cancel: "إلغاء",
  },
  yup: {
    amountInvalid: "الرجاء إدخال مبلغ صحيح",
    minAmount: "الحد الأدنى لمبلغ التبرع هو {{amount}} {{currency}}",
    emailInvalid: "البريد الإلكتروني غير صالح",
    emailRequired: "البريد الإلكتروني مطلوب",
    fullNameAlpha: "يجب أن يحتوي الاسم الكامل على حروف أبجدية ومسافات فقط",
    fullNameMin: "يجب أن يكون الاسم الكامل مكون على الأقل من حرفين",
    fullNameMax: "يجب أن لا يزيد الاسم الكامل عن 50 حرفًا",
    fullNameRequired: "الاسم الكامل مطلوب",
    addressRequired: "العنوان مطلوب",
    cityRequired: "المدينة مطلوبة",
    cardNumberInvalid: "رقم البطاقة غير صالح",
    cardNumberRequired: "رقم البطاقة مطلوب",
    expiryDateRequired: "تاريخ الانتهاء مطلوب",
    expiryDateInvalid: "لا يمكن أن يكون تاريخ الانتهاء في الماضي",
    cvvRequired: "الرمز الأمني مطلوب",
    cvvInvalid: "الرمز الأمني غير صالح",
    phoneNumberInvalid: "رقم الهاتف غير صالح",
    phoneNumberRequired: "رقم الهاتف مطلوب",
    collectionDateRequired: "تاريخ الاستلام مطلوب",
    firstNameRequired: "الاسم الأول مطلوب",
    preferredLanguageMax: "يجب أن تكون اللغة المفضلة على الأكثر 2 حرفًا",
    currencyMax: "يجب أن تكون العملة على الأكثر 3 حروف",
    dateMustBeSet: "يجب تحديد التاريخ",
    deliveryDateRequired: "تاريخ التسليم مطلوب",
    isDeliveryRequired: "اختيار استلام المشاركة مطلوب",
    quantityRequired: "الكمية مطلوبة",
    familyMembersNumberRequired: "عدد أفراد الأسرة مطلوب",
    quantityInvalid: "الرجاء إدخال عدد صحيح",
    donationValueRequired: "قيمة التبرع مطلوبة",
    donationTypeRequired: "نوع التبرع مطلوب",
    causeRequired: "القضية مطلوبة",
  },
  currency: {
    egp: "جنيه ",
    usd: "$",
  },
  donationFlow: {
    title: "اختر مبلغ التبرع",
    specialTitle: "اختر قيمة التبرع",
    zakatTitle: "اختر قيمة زكاة الفطر",
    description:
      "كل مساهمة تقدمها يمكن أن توفر الدعم الضروري لأولئك المحتاجين من خلال دائرة الإنسانية.",
    specialDescription:
      "تبرعك الكريم سيساعد في ضمان وصول اللحوم إلى الأسر والأفراد المستحقين.",
    once: "التبرع مرة واحدة،",
    monthly: "التبرع شهرياً،",
    customAmount: "أو اكتب المبلغ الخاص بك",
    placeholder: "كم تريد التبرع؟",
    stopDonation: "اختر شهر التبرع النهائي",
    final: "متى ترغب في التوقف؟",
    donation: "نوع التبرع",
    donationtype: "اختر نوع التبرع",
    next: "التالي",
    finish: "إنهاء",
    donateNow: "تبرع الآن",
    complete: "اختر طريقة الدفع",
    already: "لديك حساب؟",
    login: "تسجيل الدخول",
    or: "أو",
    signUp: "الاشتراك",
    checkout: "لإتمام عملية الشراء بسرعة",
    card: " بطاقة الائتمان",
    person: "الدفع شخصيًا",
    available: "متاح حاليًا فقط للمتبرعين الموجودين في مصر.",
    details: "أدخل التفاصيل الخاصة بك",
    name: "الاسم الكامل",
    fullname: "أدخل الاسم الكامل",
    phoneOp: "رقم الهاتف (اختيارى)",
    phonemandatory: "رقم الهاتف",
    enterPhone: "أدخل رقم هاتفك (على سبيل المثال، 123456789+)",
    collection: "تاريخ الاستلام المفضل",
    date: "اختر تاريخ الاستلام",
    expired: "تاريخ انتهاء الصلاحية",
    entercvv: "CVV أدخل رمز ",
    my: "مم / سنة",
    cardNum: "رقم البطاقة",
    cardNumberPlaceholder: "على سبيل المثال، 1234 5678 9012 3456",
    secured: "دفع آمن مع",
    emailenter: "أدخل بريدك الإلكتروني",
    email: "البريد الإلكتروني",
    address: "العنوان",
    enterAddress: "أدخل عنوانك",
    city: "المدينة",
    enterCity: "أدخل مدينتك",
    familyMembers: "يرجى إدخال عدد أفراد الأسرة",
    familyMembersPlaceHolder: "كم عدد الأفراد في عائلتك؟",
    donationValue: "قيمة التبرع",
    donationValuePlaceholder: "اختر قيمة التبرع الخاصة بك",
    quantity: "الكمية",
    quantityPlaceholder: "كم عدد الأضاحي التي ترغب في التبرع بها؟",
    receiveShare: "هل ترغب في استلام حصتك؟",
    yesShare: "نعم، أريد أن أستلم حصتي",
    noShare: "لا، أريد توزيعها على المستفيدين المحتاجين",
    deliveryAddress: "عنوان التوصيل",
    deliveryAddressPlaceholder: "يرجى توفير عنوان التوصيل الخاص بك في مصر",
    deliveryDay: "يوم التوصيل",
    deliveryDayPlaceholder: "متى ترغب في استلامها؟",
    zakatEidText: "قيمة زكاة الفطر لعام {{year}} محددة بـ {{amount}} لكل شخص.",
    globalError: "خطأ: الرجاء مراجعة وتصحيح الحقول المظللة",
    dedicate: "أهدِ تبرعي تكريمًا لشخص ما",
    honoree: "اسم المُكرَّم",
    honoreeEmail: "عنوان البريد الإلكتروني للمُكرَّم",
    optional: "(اختياري)",
    dedicationMessage: "رسالة الإهداء",
    enterHonoreeName: "أدخل اسم المُكرَّم",
    enterHonoreeEmail: "أدخل البريد الإلكتروني للمُكرَّم",
    enterMessage: "أدخل رسالتك هنا...",
    honoree_err: "يرجى إدخال اسم المكرم",
    email_err: "يرجى إدخال بريد إلكتروني صالح",
  },
  StepsContainer: {
    StepHeader: {
      donation: "التبرع",
      payment: "الدفع",
      details: "التفاصيل",
    },
    provide: "كرمك سيساعدنا في تقديم",
    nearly: "تقريبا",
    for: "فقط مقابل",
    more: "لمزيد من المساعدة، يمكنك جعلها ",
    round: "هل ترغب في تقريب المبلغ إلى",
    add: "إضافة",
    question: "؟",
    donate: "مبلغ",
    once: "التبرع مرة واحدة،",
    donation: " ",
  },
  langSelector: {
    language: "اللغة",
    currency: "العملة",
  },
  challenge: {
    selectCause: "اختر سبب التحدي",
    selectCauseDescription1: "يتيح لك تحدي المجتمع إنشاء",
    selectCauseDescription2: "حملة جمع تبرعات تعاونية",
    selectCauseDescription3:
      "لدعم قضية تهتم بها. ادعُ أصدقاءك وعائلتك ومجتمعك للمساهمة نحو هدف مشترك، مما يحقق تأثيرًا أكبر معًا.",
    all: "كل التبرعات",
    sadaqah: "الصدقة الجارية",
    creation: "إنشاء التحدي",
    next: "التالي",
    selectGoal: "حدد هدفًا ونطاق الوقت",
    writeAmount: "أو اكتب مبلغك",
    enterCustom: "أدخل مبلغًا مخصصًا",
    goalTimeRange: "نطاق زمني للتحدي",
    fromTo: "من {{start}} إلى {{end}}",
    createdBy: "تم إنشاؤها بواسطة {{person}}",
    describeChallenge: "صف تحديك",
    challengeName: "اسم التحدي",
    namePlaceholder: "سم تحديك",
    challengeDescription: "وصف التحدي",
    describePlaceholder:
      "يمكنك هنا أن تصف لماذا هذا التحدي مهم بالنسبة لك ولماذا يجب أن يكون كذلك بالنسبة للأشخاص المدعوين",
    uploadCover: "رفع غلاف",
    characters: "{{top}}/{{bottom}} حرف",
    timeRange: "نطاق الوقت",
    goal: "الهدف",
    raised: "تم جمعه",
    created: "تم إنشاء تحديك!",
    createdDescription:
      "لا تتردد في مشاركة تحديك مع عائلتك وأصدقائك وزملائك أو حتى من خلال وسائل التواصل الاجتماعي!",
    createLink: "إنشاء رابط",
    copyLink: "نسخ رابط التحدي",
    goToProfile: "اذهب إلى ملفك الشخصي",
    makeFirstDonation: "قم بأول تبرع لك",
    manageChallenges: "تحقق من تحدياتك وإدارتها",
    description:
      "تحدي المجتمع هو المكان الذي يمكنك فيه إنشاء تحديك الخاص أو متابعة التحديات الأخرى التي أنشأها الآخرون وشاركت فيها.",
    more: "المزيد من المعلومات",
    myChallenges: "تحدياتي",
    otherChallenges: "تحديات أخرى",
    step1: "أنشئ تحديك في 3 خطوات سهلة",
    step2: "شاركه مع عائلتك وأصدقائك",
    step3: "تعاونوا معًا لمساعدة كل سبب",
    step4: "انضم إلى تحديات الآخرين",
    createChallenge: "إنشاء تحدي",
    edit: "تعديل",
    closed: "مغلق",
    closedAgo: "مغلق منذ {{date}} يوم",
    startIn: "يبدأ في {{date}} يوم",
    left: "باقي {{date}} يوم",
    untilStart: "متبقي {{day}} يوم حتى البداية",
    contributed: "ساهم {{number}} شخص",
    contributedPlural: "ساهم {{number}} أشخاص",
    deadline: "الموعد النهائي",
    backToChallenges: "العودة إلى تحدياتي",
    details: "التفاصيل",
    updates: "التحديثات",
    supporters: "الداعمون",
    challengeDetails: "تفاصيل التحدي",
    currentGoal: "هدف التحدي الحالي",
    closeChallenge: "إغلاق التحدي",
    challengeUpdates: "تحديثات التحدي",
    postUpdate: "نشر تحديث",
    toBePublished: "سيتم النشر",
    writeUpdate: "اكتب تحديثًا عن تحديك...",
    endingOn: "ينتهي في {{date}}",
    donationWrap: "تلخيص التبرع",
    personalDonation: "تبرع شخصي",
    ownerUpdates: "تحديثات المالك",
    beInspired: "استلهم من المتبرعين الآخرين",
    supporterMessage: "رسالة الداعم",
    noContribution: "لا يوجد مساهمات بعد",
    noUpdates: "لا توجد تحديثات بعد",
    noSupporters: "لا يوجد داعمون بعد",
    lastWeek: "الأسبوع الماضي",
    preferenceTitle: "رسالة شكر وخصوصية البيانات",
    preferenceDescription:
      "اترك رسالة شكر لصاحب التحدي الذي نظمه وتأكد من أنك تقرر كيف سيتم عرض معلوماتك للمشاركين الآخرين في التحدي لحماية خصوصيتك.",
    thankYouMessage: "رسالة شكر",
    writeMessage: "اكتب رسالة إلى صاحب التحدي وإلى الداعمين الآخرين...",
    hideId: "إخفاء هويتي ورسالتي عن المنصة",
    hideAmount: "إخفاء مبلغ التبرع",
    confirmPreference: "تأكيد التفضيل",
    preferenceConfirmed: "تم تأكيد التفضيلات!",
    preferenceConfirmedDescription:
      "تم تأكيد رسالة الشكر الخاصة بك وتفضيلات خصوصية البيانات وتقديمها إلى المنصة!",
    goToMyProfile: "اذهب إلى ملفي الشخصي",
    previewText: "سيرى الداعمون الصفحة بهذه الطريقة",
    exitPreview: "الخروج من وضع المعاينة",
    remove: "إزالة",
    dismiss: "رفض",
    saveChanges: "حفظ التغييرات",
    saved: "تم حفظ التغييرات بنجاح",
    sortBy: "ترتيب حسب",
    date: "التاريخ",
    amount: "المبلغ",
    anonymous: "مجهول",
    hidden: "مخفي",
    donateAgain: "تبرع مرة أخرى",
    confirm: "تأكيد",
    close1: "هل أنت متأكد من أنك تريد إغلاق تحديك؟",
    close2:
      "بمجرد أن تغلق تحديك لن تتمكن من مشاركته أو تعديله بعد الآن. لا يمكن التراجع عن هذا الإجراء.",
    close3: "يرجى أخذ لحظة للتفكير في الآثار قبل المضي قدمًا.",
    me: "أنا",
    noDateRange: "لم يتم اختيار نطاق زمني",
    pickDate: "اختر تاريخًا",
    today: "اليوم",
    yesterday: "أمس",
    donatedBy: "تبرع من {{person}}",
    thankYou:
      "شكرًا لتحديد <bold>{{currencySymbol}}{{amount}}</bold> كهدف لتحديك! سيساعدنا تحديك في توفير {{mealCount}} {{roundedMealCount}} {{message}}",
    nearly: "تقريبًا",
    challengeClosed: "تم إغلاق التحدي الخاص بك",
    challengeClosedText:
      "شكرًا جزيلاً لدعمك! سنستفيد بأفضل شكل من الأموال التي جمعتها من خلال تحديك وسنبقيك على اطلاع بالتأثير الذي أحدثته!",
    sendCloseMessage: "أرسل رسالة إلى جميع {{count}} المؤيدين الذين شاركوا",
    senClosePlcaeholder:
      "اكتب رسالة لإبلاغ المشاركين بأنك أغلقت التحدي الخاص بك وشكرهم على دعمهم لتحديك...",
    sendMessage: "إرسال رسالة",
    backToProfile: "العودة إلى الملف الشخصي",
  },
};
