import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar } from "lucide-react";
import * as React from "react";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import MonthPicker from "./MonthPicker";

interface DatePickerProps {
  selected: Date | null;
  onChange: (date: Date | null) => void;
  placeholderText: string;
  className?: string;
}

export function DatePicker({
  selected,
  onChange,
  placeholderText,
  className,
}: DatePickerProps) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [popoverWidth, setPopoverWidth] = React.useState<number | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (buttonRef.current) {
      setPopoverWidth(buttonRef.current.offsetWidth);
    }
  }, [buttonRef.current]);

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          ref={buttonRef}
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            "md:text-base text-sm text-[#4F4754]",
            "border",
            "border-[#CCC2BF] h-[58px]",
            "hover:bg-transparent",
            className,
          )}
          aria-label="Choose final donation month"
        >
          <div className={cn(!selected, "flex items-center w-full")}>
            <div
              className={cn(
                "flex items-center justify-center bg-[#E2DDD7] py-2 px-6 rounded-md mr-2 rtl:ml-2 rtl:mr-0",
                "stroke-foreground",
              )}
            >
              <Calendar className="h-5 w-5 stroke-[#232329]" />
            </div>
            {selected ? (
              <span className="font-bold">{format(selected, "MMMM yyyy")}</span>
            ) : (
              <span className="text-[#4F4754]">{placeholderText}</span>
            )}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="bg-background mt-2 p-0 pointer-events-auto"
        style={{ width: popoverWidth }}
      >
        <div className="w-full">
          <MonthPicker
            currentMonth={selected || new Date()}
            onMonthChange={onChange}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default DatePicker;
